import React, { useEffect, useMemo, useState } from "react"
import Layout from "hoc/Layout"
import Hr from "components/Hr/Hr"
import Font42 from "components/Fonts/Font42"
import Font18 from "components/Fonts/Font18"
import { regions } from "constants/regions"
import { countries } from "constants/countries"
import Autocomplete from "components/Inputs/Autocomplete/Autocomplete"
import { graphql, useStaticQuery } from "gatsby"
import JobOffersList from "../../containers/JobOffersForNannys/JobOffersList/JobOffersList"
import { Grid } from "@mui/material"
import { sexs } from "constants/sexs"
import { default as PaginationComponent } from "components/Pagination/Pagination"
import Select from "components/Inputs/Select/Select"
import { orderTypes } from "../../constants/orderTypes"
import FilterComponent from "../../components/FilterContainer/FilterContainer"

const sortOptions = [
  {
    label: "Stawka - rosnąco",
    value: "SALARY_ASC",
  },
  {
    label: "Stawka - malejąco",
    value: "SALARY_DSC",
  },
  {
    label: "Start zlecenia - najpóźniej",
    value: "START_DATE_DSC",
  },
  {
    label: "Start zlecenia - najszybciej",
    value: "START_DATE_ASC",
  },
  {
    label: "Data dodania - od najstarszych",
    value: "CREATED_AT_ASC",
  },
  {
    label: "Data dodania - od najnowszych",
    value: "CREATED_AT_DSC",
  },
]

export const commonQueryString = graphql`
  fragment CommonFields on ContentfulOfertaPracyDlaOpiekunki2Connection {
    nodes {
      additionalInformation
      additionalInformationUndefined
      city
      contentful_id
      shortOrderDescription {
        shortOrderDescription
      }
      country
      currency
      jobDescription {
        raw
      }
      land
      language
      orderType
      salary
      sex
      startDate(formatString: "DD.MM")
      title
      createdAt(formatString: "DD.MM.yyyy")
    }
  }
`

const JobOffers = () => {
  const query = useStaticQuery(graphql`
    query {
      CREATED_AT_DSC: allContentfulOfertaPracyDlaOpiekunki2(
        filter: { city: { ne: "TEST" } }
        sort: { fields: createdAt, order: DESC }
      ) {
        ...CommonFields
      }
      CREATED_AT_ASC: allContentfulOfertaPracyDlaOpiekunki2(
        filter: { city: { ne: "TEST" } }
        sort: { fields: createdAt, order: ASC }
      ) {
        ...CommonFields
      }
      START_DATE_ASC: allContentfulOfertaPracyDlaOpiekunki2(
        filter: { city: { ne: "TEST" } }
        sort: { fields: startDate, order: ASC }
      ) {
        ...CommonFields
      }
      START_DATE_DSC: allContentfulOfertaPracyDlaOpiekunki2(
        filter: { city: { ne: "TEST" } }
        sort: { fields: startDate, order: DESC }
      ) {
        ...CommonFields
      }
      SALARY_DSC: allContentfulOfertaPracyDlaOpiekunki2(
        filter: { city: { ne: "TEST" } }
        sort: { fields: salary, order: DESC }
      ) {
        ...CommonFields
      }
      SALARY_ASC: allContentfulOfertaPracyDlaOpiekunki2(
        filter: { city: { ne: "TEST" } }
        sort: { fields: salary, order: ASC }
      ) {
        ...CommonFields
      }
    }
  `)
  const [sortOpt, setSortOpt] = useState("CREATED_AT_DSC")

  const defaultItems = useMemo(() => {
    if (sortOpt === "SALARY_ASC") {
      return query?.[sortOpt]?.nodes.sort((a, b) => {
        const adjustSalary = item => {
          if (item.currency === "EUR") {
            return item.salary * 4.5
          }
          return item.salary
        }

        const adjustedSalaryA = adjustSalary(a)
        const adjustedSalaryB = adjustSalary(b)

        return adjustedSalaryA - adjustedSalaryB
      })
    } else if (sortOpt === "SALARY_DSC") {
      return query?.[sortOpt]?.nodes.sort((a, b) => {
        const adjustSalary = item => {
          if (item.currency === "EUR") {
            return item.salary * 4.5
          }
          return item.salary
        }

        const adjustedSalaryA = adjustSalary(a)
        const adjustedSalaryB = adjustSalary(b)

        return adjustedSalaryB - adjustedSalaryA
      })
    }
    return query?.[sortOpt]?.nodes
  }, [sortOpt, query])

  const [items, setItems] = useState(defaultItems)
  const [filters, setFilters] = useState({
    land: "",
    sex: "",
    orderType: "",
    country: "",
  })
  const [page, setPage] = useState(1)
  const [perStep, setPerStep] = useState(10)
  const [count, setCount] = useState(Math.ceil(defaultItems.length / perStep))

  const onChangePage = (e, value) => {
    setPage(value)
  }

  const breadcrumbsData = [
    {
      title: "Strona Główna",
      link: "/",
    },
    {
      title: "Dla opiekunki",
      link: "/dla-opiekunki/etapy-rekrutacji-opiekunki-osob-starszych",
    },
    "Oferty pracy dla Opiekunki Osób Starszych",
  ]

  const handleCountryChange = (e, data) => {
    if (data) {
      setFilters(prevFilters => {
        prevFilters.country = data.label
        return { ...prevFilters }
      })
    } else {
      setFilters(prevFilters => {
        prevFilters.country = ""
        return { ...prevFilters }
      })
    }
  }
  const handleLandChange = (e, data) => {
    if (data) {
      setFilters(prevFilters => {
        prevFilters.land = data.label
        return { ...prevFilters }
      })
    } else {
      setFilters(prevFilters => {
        prevFilters.land = ""
        return { ...prevFilters }
      })
    }
  }

  const handleSexChange = (e, data) => {
    if (data) {
      setFilters(prevFilters => {
        prevFilters.sex = data.label
        return { ...prevFilters }
      })
    } else {
      setFilters(prevFilters => {
        prevFilters.sex = ""
        return { ...prevFilters }
      })
    }
  }

  const handleOrderTypeChange = (e, data) => {
    if (data) {
      setFilters(prevFilters => {
        prevFilters.orderType = data.label
        return { ...prevFilters }
      })
    } else {
      setFilters(prevFilters => {
        prevFilters.orderType = ""
        return { ...prevFilters }
      })
    }
  }

  useEffect(() => {
    setItems(defaultItems)
    setPage(1)

    if (filters.country) {
      setItems(prevItems =>
        prevItems.filter(item => item.country === filters.country)
      )
    }
    if (filters.land) {
      setItems(prevItems =>
        prevItems.filter(item => item.land === filters.land)
      )
    }

    if (filters.sex) {
      setItems(prevItems => prevItems.filter(item => item.sex === filters.sex))
    }

    if (filters.orderType) {
      setItems(prevItems =>
        prevItems.filter(item => item.orderType === filters.orderType)
      )
    }
  }, [filters, defaultItems])

  const addedFilters = Object.values(filters).filter(item => !!item).length

  return (
    <Layout
      breadcrumbsData={breadcrumbsData}
      seoTitle="Oferty pracy dla Opiekunki Osób Starszych"
    >
      <Hr mb={4} mt={12} />
      <Font42 component="h1" mb={4}>
        Oferty pracy dla Opiekunki Osób Starszych
      </Font42>
      <FilterComponent addedFilters={addedFilters}>
        <Grid item xs={12}>
          <Font18 mr={3} sx={{ width: { md: "auto", xs: "100%" } }}>
            Filtry:
          </Font18>
          <Grid item container gap={1} mb={1}>
            <Autocomplete
              autocompleteOptions={countries}
              onChange={handleCountryChange}
              placeholder="Kraj"
              sx={{ maxWidth: 320, minWidth: 320 }}
            />
            <Autocomplete
              autocompleteOptions={regions}
              onChange={handleLandChange}
              placeholder="Region"
              sx={{ maxWidth: 320, minWidth: 320 }}
            />
            <Autocomplete
              autocompleteOptions={sexs}
              onChange={handleSexChange}
              placeholder="Płeć podopiecznego"
              sx={{ maxWidth: 320, minWidth: 320 }}
            />
            <Autocomplete
              autocompleteOptions={orderTypes}
              onChange={handleOrderTypeChange}
              placeholder="Typ zlecenia"
              sx={{ maxWidth: 320, minWidth: 320 }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Font18 mr={3} sx={{ width: { md: "auto", xs: "100%" } }}>
            Sortuj:
          </Font18>
          <Grid item container gap={1} mb={1}>
            <Select
              selectOptions={sortOptions}
              defaultValue={sortOpt}
              maxWidth={320}
              onChange={(e, val) => {
                setSortOpt(val.props.value)
              }}
            />
          </Grid>
        </Grid>
      </FilterComponent>

      <JobOffersList
        items={items}
        page={page}
        filters={filters}
        setCount={setCount}
        perStep={perStep}
      />
      <Grid mt={8}>
        <PaginationComponent
          page={page}
          onChange={onChangePage}
          perStep={perStep}
          setPerStep={setPerStep}
          count={count}
        />
      </Grid>
    </Layout>
  )
}

export default JobOffers
