export const regions = [
  { label: "Badenia-Wirtembergia" },
  { label: "Bawaria" },
  { label: "Berlin" },
  { label: "Brandenburgia" },
  { label: "Brema" },
  { label: "Dolna Saksonia" },
  { label: "Hamburg" },
  { label: "Hesja" },
  { label: "Meklemburgia-Pomorze Przednie" },
  { label: "Nadrenia Północna-Westfalia" },
  { label: "Nadrenia-Palatynat" },
  { label: "Saarland" },
  { label: "Saksonia" },
  { label: "Saksonia-Anhalt" },
  { label: "Szlezwik-Holsztyn" },
  { label: "Turyngia" },
  { label: "dolnośląskie" },
  { label: "kujawsko-pomorskie" },
  { label: "lubelskie" },
  { label: "lubuskie" },
  { label: "łódzkie" },
  { label: "małopolskie" },
  { label: "mazowieckie" },
  { label: "opolskie" },
  { label: "podkarpackie" },
  { label: "podlaskie" },
  { label: "pomorskie" },
  { label: "śląskie" },
  { label: "świętokrzyskie" },
  { label: "warmińsko-mazurskie" },
  { label: "wielkopolskie" },
  { label: "zachodniopomorskie" },
]
