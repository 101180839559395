import React from "react"
import { Autocomplete as AutocompleteMui, TextField } from "@mui/material"

const Autocomplete = ({ autocompleteOptions, placeholder, sx, ...rest }) => {
  return (
    <AutocompleteMui
      disablePortal
      id="combo-box-demo"
      options={autocompleteOptions || []}
      sx={{ maxWidth: 275, minWidth: 200, ...sx }}
      {...rest}
      renderInput={params => (
        <TextField {...params} placeholder={placeholder} />
      )}
    />
  )
}

export default Autocomplete
