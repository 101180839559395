import { Grid } from "@mui/material"
import React, { useEffect, useState } from "react"
import Font18 from "components/Fonts/Font18"
import Font32 from "components/Fonts/Font32"
import JobOfferCard from "../JobOfferCard/JobOfferCard"

const JobOffersList = ({ items, page, perStep, setCount, filters }) => {
  const [itemsToDisplay, setItemsToDisplay] = useState([])

  const isFiltersEnabled = !!Object.values(filters).find(
    item => !!item === true
  )

  useEffect(() => {
    const itemsCopy = [...items]
    const parsedItemsArray = itemsCopy.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perStep)

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [] // start a new chunk
      }

      resultArray[chunkIndex].push(item)

      return resultArray
    }, [])

    setItemsToDisplay(parsedItemsArray)
    setCount(parsedItemsArray.length)
  }, [items, perStep, setCount])

  if (!items.length && isFiltersEnabled) {
    return (
      <Grid
        mt={8}
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Font32>Brak ofert dla wybranych filtrów</Font32>
        <Font18>Spróbuj zmienić opcje wyszukiwania</Font18>
      </Grid>
    )
  }

  if (!items.length) {
    return (
      <Grid
        mt={8}
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Font32>Brak ofert</Font32>
      </Grid>
    )
  }

  return (
    <Grid>
      <Font18 mt={{ xs: 0 }} mb={5}>
        Liczba ofert: {items.length}
      </Font18>
      <Grid
        container
        spacing={{ xs: 3, lg: 8 }}
        rowSpacing={4}
        justifyContent={"center"}
        component={"ul"}
      >
        {itemsToDisplay?.[page - 1]?.map(item => (
          <JobOfferCard {...item} />
        ))}
      </Grid>
    </Grid>
  )
}

export default JobOffersList
