export const sexs = [
  {
    label: "Kobieta",
    value: "Kobieta",
  },
  {
    label: "Mężczyzna",
    value: "Mężczyzna",
  },
  {
    label: "Kobieta + Mężczyzna",
    value: "Kobieta + Mężczyzna",
  },
]
