import React, { useEffect, useState } from "react"
import { Button, Collapse, Grid, useMediaQuery } from "@mui/material"
import FilterListIcon from "@mui/icons-material/FilterList"
import { StyledBadge } from "./styles"
import { theme } from "../../theme/theme"

const FilterComponent = ({ children, addedFilters }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(!isMobile)
  }, [isMobile])

  return (
    <Grid container mb={2}>
      <StyledBadge badgeContent={addedFilters}>
        <Button
          variant={open ? "outlined" : "contained"}
          color="primary"
          startIcon={<FilterListIcon />}
          onClick={() => setOpen(!open)}
        >
          Filtry
        </Button>
      </StyledBadge>

      <Grid container position="relative">
        <Collapse in={open}>
          <Grid container py={2}>
            {children}
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  )
}

export default FilterComponent
