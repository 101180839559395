import { Badge } from "@mui/material"

import styled from "styled-components"

export const StyledBadge = styled(Badge)`
  .MuiBadge-badge {
    background-color: #3ab2c4;
    color: "#fff";
  }
`
